import { useContext } from "react";
import "./BrewingPage.css";
import { observer } from "mobx-react";
import { RootRepository } from "../../repositories/RootRepository";
import { RepositoryContext } from "../../repositories/RepositoryProvider";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Paper,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";

export const BrewingPage = observer(() => {
  const rootRepo: RootRepository = useContext(RepositoryContext);
  const data = rootRepo.brewRepository.brewData;

  if (data !== undefined && data.length > 0) {
    data.forEach((brew) => {
      console.log(brew);
    });
    return (
      <div className="EntireComponent">
        {data.map((brew) => (
          <Card key={brew.id} sx={{ maxWidth: 400, mb: 5 }}>
            <CardMedia
              component="img"
              height="140"
              image={
                brew.imageLink ??
                "https://www.daysoftheyear.com/cdn-cgi/image/dpr=1%2Cf=auto%2Cfit=pad%2Cheight=675%2Cmetadata=none%2Conerror=redirect%2Cq=85%2Cwidth=1200/wp-content/uploads/lager-day-1.jpg"
              }
              alt="Hoppy Lager"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {brew.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {brew.description}
              </Typography>
            </CardContent>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                "& > :not(style)": {
                  m: 1,
                },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Paper elevation={3} sx={{ p: 1 }}>
                <Typography sx={{ fontSize: 15 }} color="text.secondary">
                  Current Gravity
                </Typography>
                <Typography height={20} sx={{ fontSize: 15 }} color="green">
                  {brew.currentSg?.toPrecision(5) ?? "Unknown"}
                </Typography>
              </Paper>
              <Paper elevation={3} sx={{ p: 1 }}>
                <Typography
                  sx={{ fontSize: 15 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Current Alchohol
                </Typography>
                <Typography sx={{ fontSize: 15 }} color="green" gutterBottom>
                  {brew.currentAbv?.toPrecision(2) + "%" ?? "Unknown"}
                </Typography>
              </Paper>
              <Paper elevation={3} sx={{ p: 1 }}>
                <Typography
                  sx={{ fontSize: 15 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Two Day Change
                </Typography>
                <Typography
                  sx={{ fontSize: 15 }}
                  color={brew.currentlyFermenting ? "green" : "red"}
                  gutterBottom
                >
                  {"+" + brew.twoDayAbvChange?.toPrecision(3) + "%" ??
                    "Unknown"}
                </Typography>
              </Paper>
              <Paper elevation={3} sx={{ p: 1 }}>
                <Typography
                  sx={{ fontSize: 15 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Five Day Change
                </Typography>
                <Typography
                  sx={{ fontSize: 15 }}
                  color={brew.currentlyFermenting ? "green" : "red"}
                  gutterBottom
                >
                  {"+" + brew.fiveDayAbvChange?.toPrecision(3) + "%" ??
                    "Unknown"}
                </Typography>
              </Paper>
              <Paper elevation={3} sx={{ p: 1 }}>
                <Typography
                  sx={{ fontSize: 15 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Currently Fermenting?
                </Typography>
                <Typography
                  sx={{ fontSize: 15 }}
                  color={brew.currentlyFermenting ? "green" : "red"}
                  gutterBottom
                >
                  {brew.currentlyFermenting ? "Yes" : "No"}
                </Typography>
              </Paper>
            </Box>
            <CardActions>
              <Link to={"/brewpage/" + brew.id}>
                <Button size="small">View</Button>
              </Link>
              <Button size="small" href={brew.recipeLink}>
                Recipe
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>
    );
  } else {
    return <div>No data loaded.</div>;
  }
});
