import { useContext, useState } from "react";
import "./BrewGraphView.css";
import { observer } from "mobx-react";
import { RootRepository } from "../../repositories/RootRepository";
import { RepositoryContext } from "../../repositories/RepositoryProvider";
import {
  AnimatedAxis,
  AnimatedGrid,
  AnimatedLineSeries,
  XYChart,
  Tooltip,
  lightTheme,
} from "@visx/xychart";
import { FormControl, MenuItem, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import { IBrewSpindelData } from "../../repositories/BrewRepository";

enum yEnum {
  temperature,
  angle,
  battery,
  specificgravity,
  abv,
  runningAbv,
  runningAngle,
}

function GetData(currentY: yEnum) {
  switch (currentY) {
    case yEnum.angle:
      return {
        label: "Angle (°)",
        tooltipText: (val: number) => {
          return val.toString() + "°";
        },
        yScale: {
          type: "linear",
          zero: false,
          nice: true,
        },
      };
    case yEnum.runningAngle:
      return {
        label: "Angle (°)",
        tooltipText: (val: number) => {
          return val.toString() + "°";
        },
        yScale: {
          type: "linear",
          zero: false,
          nice: true,
        },
      };
    case yEnum.battery:
      return {
        label: "Battery (V)",
        tooltipText: (val: number) => {
          return val.toString() + "V";
        },
        yScale: {
          type: "linear",
          zero: false,
          nice: true,
        },
      };
    case yEnum.temperature:
      return {
        label: "Temperature (°C)",
        tooltipText: (val: number) => {
          return val.toString() + "°C";
        },
        yScale: {
          type: "linear",
          zero: false,
          nice: true,
        },
      };
    case yEnum.specificgravity:
      return {
        label: "Specific Gravity",
        tooltipText: (val: number) => {
          return val.toString() + "sg";
        },
        yScale: {
          type: "linear",
          domain: [1.0, 1.06],
          zero: false,
          nice: true,
        },
      };
    case yEnum.abv:
      return {
        label: "Alcohol By Volume",
        tooltipText: (val: number) => {
          return val.toString() + "%";
        },
        yScale: {
          type: "linear",
          zero: true,
          nice: true,
        },
      };
    case yEnum.runningAbv:
      return {
        label: "Abv Avg",
        tooltipText: (val: number) => {
          return val.toString() + "%";
        },
        yScale: {
          type: "linear",
          zero: true,
          nice: true,
        },
      };
  }
}

export const BrewGraphView = observer(() => {
  const rootRepo: RootRepository = useContext(RepositoryContext);
  const [yData, setYData] = useState(yEnum.abv);
  const params = useParams();
  const brewData = rootRepo.brewRepository.brewData;
  var brew;
  var filteredData: IBrewSpindelData[];
  if (brewData !== null) {
    brew = brewData.find((c) => c.id === params.id);
    filteredData = brew?.spindelData ?? new Array<IBrewSpindelData>();
  } else {
    return <div>No data loaded brew.</div>;
  }

  if (
    brew !== null &&
    brew !== undefined &&
    filteredData !== undefined &&
    filteredData.length > 0
  ) {
    const originalGravity = brew.startGravity ?? filteredData[0].gravity;
    const accessors = {
      xAccessor: (d: IBrewSpindelData) => d.unixtimestamp,
      yAccessor: (d: IBrewSpindelData) => {
        switch (yData) {
          case yEnum.angle:
            return d.angle;
          case yEnum.battery:
            return d.battery;
          case yEnum.temperature:
            return d.temperature;
          case yEnum.specificgravity:
            return d.gravity;
          case yEnum.abv:
            return (originalGravity - d.gravity) * 131.25;
          case yEnum.runningAbv:
            return d.runningAbvAverage;
          case yEnum.runningAngle:
            return d.runningAngleAverage;
        }
      },
    };

    return (
      <div className="EntireComponent">
        <div>
          <FormControl>
            <Select
              value={yData}
              onChange={(c) => setYData(c.target.value as yEnum)}
            >
              <MenuItem value={yEnum.abv}>Alchohol By Volume</MenuItem>
              <MenuItem value={yEnum.specificgravity}>
                Specific Gravity
              </MenuItem>
              <MenuItem value={yEnum.angle}>Angle</MenuItem>
              <MenuItem value={yEnum.battery}>Battery</MenuItem>
              <MenuItem value={yEnum.temperature}>Temperature</MenuItem>
              <MenuItem value={yEnum.runningAbv}>Avg Abv</MenuItem>
              <MenuItem value={yEnum.runningAngle}>Avg Angle</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="SpindelChartBackground">
          <XYChart
            height={400}
            width={undefined}
            margin={{ left: 60, top: 35, bottom: 38, right: 27 }}
            theme={lightTheme}
            xScale={{ type: "time" }}
            yScale={GetData(yData).yScale as any}
          >
            <AnimatedAxis
              orientation="bottom"
              label="Time"
              animationTrajectory="min"
              numTicks={5}
            />
            <AnimatedGrid
              columns={false}
              numTicks={4}
              lineStyle={{
                stroke: "#e1e1e1",
                strokeLinecap: "round",
                strokeWidth: 1,
              }}
              strokeDasharray="0, 4"
              animationTrajectory="min"
            />
            <AnimatedLineSeries
              dataKey={brew?.name ?? "Null"}
              data={filteredData}
              {...accessors}
            />
            <AnimatedAxis
              hideAxisLine
              hideTicks
              orientation="left"
              numTicks={4}
              labelOffset={20}
              label={GetData(yData).label}
              animationTrajectory="min"
            />
            <Tooltip
              snapTooltipToDatumX
              snapTooltipToDatumY
              showVerticalCrosshair
              showSeriesGlyphs
              renderTooltip={({ tooltipData, colorScale }) => (
                <div>
                  <div
                    style={{
                      color: colorScale
                        ? colorScale(tooltipData?.nearestDatum?.key as string)
                        : undefined,
                    }}
                  >
                    {tooltipData?.nearestDatum?.key}
                  </div>
                  {(
                    tooltipData?.nearestDatum?.datum as IBrewSpindelData
                  ).datetimestamp.toFormat("F")}
                  <p></p>
                  {GetData(yData).tooltipText(
                    accessors.yAccessor(
                      tooltipData?.nearestDatum?.datum as IBrewSpindelData
                    )
                  )}
                </div>
              )}
            />
          </XYChart>
        </div>
        <header className="SpindelGraph-header">Spindel Table Data</header>
        <table className="table table-striped" aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Time</th>
              <th>Temp. (C)</th>
              <th>Specific Gravity</th>
            </tr>
          </thead>
          <tbody>
            {filteredData
              .slice()
              .sort((c1, c2) => c2.unixtimestamp - c1.unixtimestamp)
              .map((data) => (
                <tr key={data.unixtimestamp}>
                  <td>
                    {data.datetimestamp.setZone("UTC+1").toFormat("EEE d',' T")}
                  </td>
                  <td>{data.temperature}</td>
                  <td>{data.gravity}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div>No data loaded second.</div>;
  }
});
