import { useContext, useState } from "react";
import "./SpindelGraph.css";
import { observer } from "mobx-react";
import { RootRepository } from "../../repositories/RootRepository";
import { RepositoryContext } from "../../repositories/RepositoryProvider";
import {
  AnimatedAxis,
  AnimatedGrid,
  AnimatedLineSeries,
  XYChart,
  Tooltip,
  lightTheme,
} from "@visx/xychart";
import { ISpindelData } from "../../repositories/SpindelRepository";
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";

enum yEnum {
  temperature,
  angle,
  battery,
  specificgravity,
  abv,
}

function GetData(currentY: yEnum) {
  switch (currentY) {
    case yEnum.angle:
      return {
        label: "Angle (°)",
        tooltipText: (val: number) => {
          return val.toString() + "°";
        },
        yScale: {
          type: "linear",
          zero: false,
          rangePadding: 5,
        },
      };
    case yEnum.battery:
      return {
        label: "Battery (V)",
        tooltipText: (val: number) => {
          return val.toString() + "V";
        },
        yScale: {
          type: "linear",
          zero: false,
        },
      };
    case yEnum.temperature:
      return {
        label: "Temperature (°C)",
        tooltipText: (val: number) => {
          return val.toString() + "°C";
        },
        yScale: {
          type: "linear",
          zero: false,
        },
      };
    case yEnum.specificgravity:
      return {
        label: "Specific Gravity",
        tooltipText: (val: number) => {
          return val.toString() + "sg";
        },
        yScale: {
          type: "linear",
          domain: [1.0, 1.05],
          zero: false,
        },
      };
    case yEnum.abv:
      return {
        label: "Estimated Alcohol By Volume",
        tooltipText: (val: number) => {
          return val.toString() + "%";
        },
        yScale: {
          type: "linear",
          zero: true,
        },
      };
  }
}
export const SpindelGraphView = observer(() => {
  const spindelStore: RootRepository = useContext(RepositoryContext);
  const data = spindelStore.spindelRepository.spindelData;
  const [autoresize, setAutoresize] = useState(true);
  const [yData, setYData] = useState(yEnum.specificgravity);

  if (data !== undefined && data.length > 0) {
    const first = data[0].datetimestamp;
    const last = data[data.length - 1].datetimestamp;
    const numDays = Math.ceil(last.diff(first).as("days"));
    const numPixelsPerDay = 200;
    const width = numPixelsPerDay * numDays;

    const allSpindels = data
      .map((item) => item.spindelId)
      .filter((value, index, self) => self.indexOf(value) === index);
    // const allSpindels = new Set<string>(data.map((item) => item.spindelId));
    var originalGravities: any = {};
    allSpindels.forEach((c: string) => {
      originalGravities[c] = data.find((v) => v.spindelId === c)?.gravity;
    });
    // const originalGravity = data.find(c => c.);
    console.log(originalGravities);

    const accessors = {
      xAccessor: (d: ISpindelData) => d.unixtimestamp,
      yAccessor: (d: ISpindelData) => {
        switch (yData) {
          case yEnum.angle:
            return d.angle;
          case yEnum.battery:
            return d.battery;
          case yEnum.temperature:
            return d.temperature;
          case yEnum.specificgravity:
            return d.gravity;
          case yEnum.abv:
            console.log(originalGravities[d.spindelId]);
            return (originalGravities[d.spindelId] - d.gravity) * 131.25;
        }
      },
    };

    console.log(data);
    return (
      <div className="EntireComponent">
        <header className="SpindelGraph-header">Spindel Graph</header>
        <div>
          <FormControl>
            <FormControlLabel
              control={
                <Select
                  value={yData}
                  onChange={(c) => setYData(c.target.value as yEnum)}
                >
                  <MenuItem value={yEnum.abv}>
                    Estimated Alcohol By Volume
                  </MenuItem>
                  <MenuItem value={yEnum.specificgravity}>
                    Specific Gravity
                  </MenuItem>
                  <MenuItem value={yEnum.angle}>Angle</MenuItem>
                  <MenuItem value={yEnum.battery}>Battery</MenuItem>
                  <MenuItem value={yEnum.temperature}>Temperature</MenuItem>
                </Select>
              }
              label="Charted Value"
            />
          </FormControl>
          <FormControl className="graph-buttons">
            <FormControlLabel
              control={
                <Switch
                  title="test"
                  checked={autoresize}
                  onChange={() => setAutoresize(!autoresize)}
                />
              }
              label="Fit to screen"
            />
          </FormControl>
        </div>
        <div
          className={
            autoresize
              ? "SpindelChartBackground"
              : "SpindelChartBackground-Scroll"
          }
        >
          <XYChart
            height={400}
            width={autoresize ? undefined : width}
            margin={{ left: 60, top: 35, bottom: 38, right: 27 }}
            theme={lightTheme}
            xScale={{ type: "time" }}
            yScale={GetData(yData).yScale as any}
          >
            <AnimatedAxis
              orientation="bottom"
              label="Time"
              animationTrajectory="min"
              numTicks={autoresize ? 5 : undefined}
            />
            <AnimatedGrid
              columns={false}
              numTicks={4}
              lineStyle={{
                stroke: "#e1e1e1",
                strokeLinecap: "round",
                strokeWidth: 2,
              }}
              animationTrajectory="min"
            />
            <AnimatedLineSeries
              dataKey="DadsDoombar"
              data={data.filter((c) => c.name === "RedPanda")}
              {...accessors}
            />
            <AnimatedLineSeries
              dataKey="DadsLager"
              data={data.filter((c) => c.name === "GreenKoala")}
              {...accessors}
            />
            <AnimatedLineSeries
              dataKey="MikesLager"
              data={data.filter((c) => c.name === "iSpindelScrivendel")}
              {...accessors}
            />
            <AnimatedAxis
              hideAxisLine
              hideTicks
              orientation="left"
              numTicks={4}
              tickLabelProps={() => ({ dx: -10 })}
              label={GetData(yData).label}
              animationTrajectory="min"
            />
            <Tooltip
              snapTooltipToDatumX
              snapTooltipToDatumY
              showVerticalCrosshair
              showSeriesGlyphs
              renderTooltip={({ tooltipData, colorScale }) => (
                <div>
                  <div
                    style={{
                      color: colorScale
                        ? colorScale(tooltipData?.nearestDatum?.key as string)
                        : undefined,
                    }}
                  >
                    {tooltipData?.nearestDatum?.key}
                  </div>
                  {(
                    tooltipData?.nearestDatum?.datum as ISpindelData
                  ).datetimestamp.toFormat("F")}
                  <p></p>
                  {GetData(yData).tooltipText(
                    accessors.yAccessor(
                      tooltipData?.nearestDatum?.datum as ISpindelData
                    )
                  )}
                </div>
              )}
            />
          </XYChart>
        </div>
        <header className="SpindelGraph-header">Spindel Table Data</header>
        <table className="table table-striped" aria-labelledby="tabelLabel">
          <thead>
            <tr>
              <th>Name</th>
              <th>Time</th>
              <th>Temp. (C)</th>
              <th>Specific Gravity</th>
            </tr>
          </thead>
          <tbody>
            {data
              .slice()
              .sort((c1, c2) => c2.unixtimestamp - c1.unixtimestamp)
              .map((data) => (
                <tr key={data.unixtimestamp}>
                  <td>{data.name}</td>
                  <td>{data.datetimestamp.setZone("UTC+1").toFormat("f")}</td>
                  <td>{data.temperature}</td>
                  <td>{data.gravity}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    );
  } else {
    return <div>No data loaded.</div>;
  }
});
