import axios from "axios";
import { IBrew } from "../BrewRepository";
import { ISpindelData } from "../SpindelRepository";

export class SpindelService {
  private service;

  constructor() {
    this.service = axios.create({
      baseURL: "https://ispindeldatastore.azurewebsites.net/api",
    });
  }

  getAllUnassignedData() {
    return this.service.get<ISpindelData[]>("/ispindelreadall");
  }

  getAllBrews() {
    return this.service.get<IBrew[]>("/getallbrews");
  }
}
