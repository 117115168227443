import { FC, createContext, ReactNode, ReactElement } from "react";
import { RootRepository } from "./RootRepository";

export const RepositoryContext = createContext<RootRepository>(
  {} as RootRepository
);

export type RepositoryComponent = FC<{
  repository: RootRepository;
  children: ReactNode;
}>;

export const RepositoryProvider: RepositoryComponent = ({
  children,
  repository,
}): ReactElement => {
  return (
    <RepositoryContext.Provider value={repository}>
      {children}
    </RepositoryContext.Provider>
  );
};
