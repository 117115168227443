import "./Login.css";
import { observer } from "mobx-react";
import { Button, Typography } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { IPublicClientApplication } from "@azure/msal-browser";

export const Login = observer(() => {
  const { instance } = useMsal();

  function handleLogin(i: IPublicClientApplication) {
    i.loginRedirect(loginRequest);
  }

  return (
    <Button
      sx={{
        fontSize: 20,
        color: "white",
        backgroundColour: "white",
        borderColor: "white !important",
      }}
      variant="outlined"
      onClick={() => handleLogin(instance)}
    >
      <Typography>Login to Quench Your Thirst</Typography>
    </Button>
  );
});
