import "./App.css";
import { ResponsiveDrawer } from "./components/ResponsiveDrawer/ResponsiveDrawer";
// import { useIsAuthenticated } from "@azure/msal-react";
// import { Login } from "./components/Login/Login";

function App() {
  // const isAuthenticated = useIsAuthenticated();
  // if (isAuthenticated) {
  return (
    <div className="App">
      <ResponsiveDrawer></ResponsiveDrawer>
    </div>
  );
  // }
  // return (
  //   <div className="App">
  //     <Login></Login>
  //   </div>
  // );
}

export default App;
