import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import SportsBarIcon from "@mui/icons-material/SportsBar";
import CottageIcon from "@mui/icons-material/Cottage";
import RefreshIcon from "@mui/icons-material/Refresh";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Home } from "@mui/icons-material";
import { Route, Link, Routes } from "react-router-dom";
import { SpindelGraphView } from "../spindelGraph/SpindelGraph";
import { BrewPage } from "../BrewPage/BrewPage";
import { BrewingPage } from "../BrewingPage/BrewingPage";
import { useIsAuthenticated } from "@azure/msal-react";
import { Logout } from "../Logout/Logout";
import { Login } from "../Login/Login";
import { CircularProgress, Fab } from "@mui/material";
import { RootRepository } from "../../repositories/RootRepository";
import { RepositoryContext } from "../../repositories/RepositoryProvider";
import { observer } from "mobx-react";

const drawerWidth = 240;

export const ResponsiveDrawer = observer(() => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const rootRepo: RootRepository = React.useContext(RepositoryContext);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const isAuthenticated = useIsAuthenticated();

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem button key={"Home"} component={Link} to={"brewingpage"}>
          <ListItemIcon>
            <CottageIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} />
        </ListItem>
        <ListItem
          button
          key={"Brewing Test Page"}
          component={Link}
          to={"brewing"}
        >
          <ListItemIcon>
            <SportsBarIcon />
          </ListItemIcon>
          <ListItemText primary={"Brewing Test Page"} />
        </ListItem>
        <ListItem button key={"Brewing"} component={Link} to={"brewingpage"}>
          <ListItemIcon>
            <SportsBarIcon />
          </ListItemIcon>
          <ListItemText primary={"Brewing"} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key={"Settings"} component={Link} to={"settings"}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary={"Settings"} />
        </ListItem>
      </List>
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            noWrap
            sx={{ flexGrow: 1, textAlign: "left", ml: 2 }}
          >
            Scriven's Projects
          </Typography>
          {isAuthenticated ? <Logout></Logout> : <Login></Login>}
        </Toolbar>
      </AppBar>

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Box
          sx={{
            position: "fixed",
            width: { xs: "100%", sm: `calc(100% - ${drawerWidth}px)` },
            height: "100vh",
            pointerEvents: "none",
          }}
        >
          <Fab
            color="secondary"
            aria-label="refresh"
            onClick={() => rootRepo.refreshAll()}
            sx={{
              position: "absolute",
              bottom: "16px",
              right: "16px",
              pointerEvents: "all",
            }}
          >
            {rootRepo.isLoading ? (
              <CircularProgress key="a" />
            ) : (
              <RefreshIcon key="b" />
            )}
          </Fab>
        </Box>
        <Toolbar />
        <Box>
          <Routes>
            <Route path="brewing" element={<SpindelGraphView />} />
            <Route path="settings" element={<SettingsIcon />} />
            <Route path="brewingpage" element={<BrewingPage />} />
            <Route path="brewpage/:id" element={<BrewPage />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
});
