import {
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { DateTime } from "luxon";
import { RootRepository } from "./RootRepository";
import { SpindelService } from "./services/SpindelService";

export interface ISpindelData {
  name: string;
  spindelId: string;
  token: string | undefined;
  angle: number;
  temperature: number;
  temp_Units: string;
  battery: number;
  gravity: number;
  interval: number;
  rssi: number;
  timeStamp: string;
  datetimestamp: DateTime;
  unixtimestamp: number;
}

export enum SpindelRepositoryStatus {
  Loading,
  Loaded,
  Error,
}

export class SpindelRepository {
  private rootRepo;
  private service;
  isLoading: boolean;
  spindelData: IObservableArray<ISpindelData>;

  constructor(rootRepo: RootRepository) {
    makeObservable(this, {
      isLoading: observable,
    });
    this.spindelData = observable.array<ISpindelData>([], { deep: false });
    this.rootRepo = rootRepo;
    this.service = new SpindelService();

    // For now load automatically on creation
    this.isLoading = false;
    this.loadAllUnassignedData();
  }

  async loadAllUnassignedData() {
    console.info("Loading data in SpindelRepository");

    if (this.isLoading) {
      console.info("Already loading SpindelRepository Data");
      return;
    }
    runInAction(() => {
      this.isLoading = true;
    });
    try {
      var response = await this.service.getAllUnassignedData();
      console.info("Got response spindel repo:");
      console.info(response);
      var data = response.data;
      data.forEach((d) => {
        d.datetimestamp = DateTime.fromISO(d.timeStamp, { zone: "utc" }); //Math.floor(new Date(d.timeStamp).getTime() / 1000);
        d.unixtimestamp = d.datetimestamp.toUnixInteger() * 1000;

        if (d.spindelId === "13455236") {
          d.gravity =
            0.9918908929907366 + 0.0008035714248118262 * d.angle + 0.02;
        }

        if (d.spindelId === "14546967") {
          d.gravity = d.gravity - 0.0045;
        }

        if (d.name === "BLWCider2022") {
          d.gravity = d.gravity - 0.006348;
        }
      });
      runInAction(() => {
        this.spindelData.replace(data);
        this.rootRepo.brewRepository.handleSpindelData(this.spindelData);
        this.isLoading = false;
        console.info("SpindelRepo: " + this.isLoading);
      });
    } catch (error) {
      this.isLoading = false;
      console.error("Something went wrong loading data in SpindelRepository");
      throw error;
    }
  }
}
