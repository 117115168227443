import { DateTime } from "luxon";
import {
  IObservableArray,
  makeObservable,
  observable,
  runInAction,
} from "mobx";
import { RootRepository } from "./RootRepository";
import { SpindelService } from "./services/SpindelService";
import { ISpindelData } from "./SpindelRepository";

export interface IBrew {
  name: string;
  id: string;
  description?: string;
  image?: string;
  spindelId?: string;
  recipeLink?: string;
  startDate?: string;
  startDateTime?: DateTime;
  startDateUnix?: number;
  endDate?: string;
  endDateTime?: DateTime;
  endDateUnix?: number;
  startGravity?: number;
  imageLink?: string;
  currentlyFermenting?: boolean;
  currentSg?: number;
  currentAbv?: number;
  twoDayAbvChange?: number;
  fiveDayAbvChange?: number;
  spindelData?: IBrewSpindelData[];
}

export interface IBrewSpindelData extends ISpindelData {
  angle: number;
  temperature: number;
  battery: number;
  gravity: number;
  abv: number;
  rssi: number;
  timeStamp: string;
  datetimestamp: DateTime;
  unixtimestamp: number;
  runningAngleAverage: number;
  runningAbvAverage: number;
  runningSpecificGravityAverage: number;
}

export enum BrewRepositoryStatus {
  NotLoaded,
  Loading,
  Loaded,
  Error,
}

function addSimpleMovingAverage(brews: IBrewSpindelData[], window = 5) {
  if (!brews || brews.length < window) {
    return;
  }

  let index = -1; //window - 1;
  const length = brews.length + 1;

  while (++index < length) {
    const currentBrew = brews[index];
    if (currentBrew == null) continue;
    if (index < window - 1) {
      currentBrew.runningAbvAverage = currentBrew.abv;
      currentBrew.runningAngleAverage = currentBrew.angle;
      currentBrew.runningSpecificGravityAverage = currentBrew.gravity;
      continue;
    }
    const windowSlice = brews.slice(index - window, index);

    if (windowSlice.length === 0) {
      continue;
    }

    //const abvSum = windowSlice.reduce((prev, curr) => prev + curr.abv, 0);
    currentBrew.runningAbvAverage = median(windowSlice.map((c) => c.abv)); //abvSum / window;

    //const angleSum = windowSlice.reduce((prev, curr) => prev + curr.angle, 0);
    currentBrew.runningAngleAverage = median(windowSlice.map((c) => c.angle)); //angleSum / window;

    //const sgSum = windowSlice.reduce((prev, curr) => prev + curr.gravity, 0);
    currentBrew.runningSpecificGravityAverage = median(
      windowSlice.map((c) => c.gravity)
    ); //sgSum / window;
  }
}

function convert(matchingData: ISpindelData[]): IBrewSpindelData[] {
  return matchingData.map((c) => c as IBrewSpindelData);
}

function addComputedValued(brewDatum: IBrew) {
  if (brewDatum.spindelData == null) return;
  const startSg =
    brewDatum.startGravity ?? brewDatum?.spindelData.at(0)?.gravity;
  if (startSg !== null && startSg !== undefined) {
    brewDatum.spindelData.forEach((spindelDatum) => {
      spindelDatum.abv = convertSgToAbv(startSg, spindelDatum.gravity);
    });
  }
}

function median(values: number[]): number {
  if (values.length === 0) return -1;

  values.sort(function (a, b) {
    return a - b;
  });

  var half = Math.floor(values.length / 2);

  if (values.length % 2) return values[half];

  return (values[half - 1] + values[half]) / 2.0;
}

function addSummaryValues(brewDatum: IBrew) {
  if (brewDatum.spindelData == null) return;

  const lastdata = brewDatum.spindelData[brewDatum.spindelData?.length - 1];
  brewDatum.currentSg =
    lastdata.runningSpecificGravityAverage ?? lastdata.gravity;
  brewDatum.currentAbv = lastdata.runningAbvAverage ?? lastdata.abv;

  // Todo

  const reversedData = brewDatum.spindelData.slice().reverse();
  const twoDaysAgo = reversedData[0].datetimestamp.minus({
    days: 1,
    hours: 18,
  });
  const abvTwoDaysAgo =
    reversedData.find((d) => d.datetimestamp <= twoDaysAgo)
      ?.runningAbvAverage ??
    brewDatum.spindelData.find((c) => c.runningAbvAverage > 0)
      ?.runningAbvAverage ??
    0;
  brewDatum.twoDayAbvChange = Math.abs(
    lastdata.runningAbvAverage - abvTwoDaysAgo
  );
  const fiveDaysAgo = reversedData[0].datetimestamp.minus({
    days: 4,
    hours: 18,
  });
  const abvFiveDaysAgo =
    reversedData.find((d) => d.datetimestamp <= fiveDaysAgo)
      ?.runningAbvAverage ??
    brewDatum.spindelData.find((c) => c.runningAbvAverage > 0)
      ?.runningAbvAverage ??
    0;
  console.log(abvFiveDaysAgo);
  console.log(lastdata.runningAbvAverage);
  brewDatum.fiveDayAbvChange = Math.abs(
    lastdata.runningAbvAverage - abvFiveDaysAgo
  );
  brewDatum.currentlyFermenting =
    brewDatum.twoDayAbvChange >= 0.01 || brewDatum.currentSg > 1.03;
}

function convertSgToAbv(startSg: number, endSg: number): number {
  return (startSg - endSg) * 131.25;
}

export class BrewRepository {
  private rootRepo;
  private service;
  isLoading: boolean;
  brewData: IObservableArray<IBrew>;
  spindelData: ISpindelData[] | undefined;

  constructor(rootRepo: RootRepository) {
    makeObservable(this, {
      isLoading: observable,
    });
    this.isLoading = false;
    this.brewData = observable.array<IBrew>([], { deep: false });
    this.rootRepo = rootRepo;
    this.service = new SpindelService();
    // For now load automatically on creation
    this.loadAllBrews();
  }

  handleSpindelData(data: ISpindelData[]) {
    if (data !== undefined && data !== null) {
      console.log("Calculating");
      this.brewData.forEach((brewDatum) => {
        const matchingData = data.filter(
          (c) =>
            c.spindelId === brewDatum.spindelId &&
            brewDatum.startDateTime != null &&
            c.datetimestamp > brewDatum.startDateTime
        );

        brewDatum.spindelData = convert(matchingData);
        addComputedValued(brewDatum);
        addSimpleMovingAverage(brewDatum.spindelData, 24);
        addSummaryValues(brewDatum);
      });
      this.brewData.replace(this.brewData);

      console.info("BrewRepository1: " + this.isLoading);
      runInAction(() => {
        this.isLoading = false;
        console.info("BrewRepository2: " + this.isLoading);
      });
    } else {
      this.spindelData = data;

      runInAction(() => {
        this.isLoading = true;
        console.info("BrewRepository3: " + this.isLoading);
      });
    }
  }

  async loadAllBrews() {
    console.info("Loading data in BrewRepository: " + this.isLoading);

    if (this.isLoading) {
      console.info("Already loading BrewRepository Data");
      return;
    }

    runInAction(() => {
      this.isLoading = true;
    });

    try {
      var response = await this.service.getAllBrews();
      var data = response.data;
      console.log("brew data");
      console.log(data);

      runInAction(() => {
        this.brewData.replace(data.reverse());
        data.forEach((d) => {
          if (d.startDate !== undefined && d.startDate !== null) {
            d.startDateTime = DateTime.fromISO(d.startDate, { zone: "utc" });
            d.startDateUnix = d.startDateTime.toUnixInteger() * 1000;
          }
          if (d.endDate !== undefined && d.endDate !== null) {
            console.log("Setting end date: " + d.endDate);
            d.endDateTime = DateTime.fromISO(d.endDate, { zone: "utc" });
            d.endDateUnix = d.endDateTime.toUnixInteger() * 1000;
          }
        });
        this.isLoading = false;
        this.handleSpindelData(this.spindelData as ISpindelData[]);
      });
    } catch (error) {
      this.isLoading = false;
      console.error("Something went wrong loading data in BrewRepository");
      throw error;
    }
  }
}
