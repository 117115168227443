import "./Logout.css";
import { observer } from "mobx-react";
import { Button } from "@mui/material";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

export const Logout = observer(() => {
  const { instance } = useMsal();

  function handleLogout(i: IPublicClientApplication) {
    i.logoutRedirect();
  }

  return (
    <Button color="inherit" onClick={() => handleLogout(instance)}>
      Logout
    </Button>
  );
});
