import { useContext } from "react";
import "./BrewPage.css";
import { observer } from "mobx-react";
import { RootRepository } from "../../repositories/RootRepository";
import { RepositoryContext } from "../../repositories/RepositoryProvider";
import { useParams } from "react-router-dom";
import { BrewGraphView } from "../brewGraph/BrewGraphView";
import { Box, Paper, Typography } from "@mui/material";

export interface BrewProps {
  id: string;
}

export const BrewPage = observer(() => {
  const rootRepo: RootRepository = useContext(RepositoryContext);
  const params = useParams();
  const data = rootRepo.brewRepository.brewData;
  const spindelData = rootRepo.spindelRepository.spindelData;

  if (
    data !== undefined &&
    data !== null &&
    data.length > 0 &&
    spindelData !== undefined &&
    spindelData !== null &&
    spindelData.length > 0
  ) {
    const brew = data.find((c) => c.id === params.id);
    if (brew === undefined) {
      return <div>No brew of that id: params.id</div>;
    }

    const isFermenting = brew.currentlyFermenting; //Math.abs(twoDayChange) > 0.01;

    return (
      <div className="EntireComponent">
        <header className="SpindelGraph-header">{brew.name}</header>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
            },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper elevation={3} sx={{ p: 1 }}>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Starting Gravity
            </Typography>
            <Typography sx={{ fontSize: 30 }} color="green" gutterBottom>
              {brew.startGravity ??
                brew.spindelData?.at(0)?.gravity ??
                "Unknown"}
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ p: 1 }}>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Current Gravity
            </Typography>
            <Typography sx={{ fontSize: 30 }} color="green" gutterBottom>
              {brew.currentSg?.toPrecision(5) ?? "Unknown"}
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ p: 1 }}>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Current Alchohol
            </Typography>
            <Typography sx={{ fontSize: 30 }} color="green" gutterBottom>
              {brew.currentAbv?.toPrecision(2) + "%" ?? "Unknown"}
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ p: 1 }}>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Two Day Change
            </Typography>
            <Typography
              sx={{ fontSize: 30 }}
              color={isFermenting ? "green" : "red"}
              gutterBottom
            >
              {"+" + brew.twoDayAbvChange?.toPrecision(3) + "%" ?? "Unknown"}
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ p: 1 }}>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Five Day Change
            </Typography>
            <Typography
              sx={{ fontSize: 30 }}
              color={isFermenting ? "green" : "red"}
              gutterBottom
            >
              {"+" + brew.fiveDayAbvChange?.toPrecision(3) + "%" ?? "Unknown"}
            </Typography>
          </Paper>
          <Paper elevation={3} sx={{ p: 1 }}>
            <Typography
              sx={{ fontSize: 20 }}
              color="text.secondary"
              gutterBottom
            >
              Currently Fermenting?
            </Typography>
            <Typography
              sx={{ fontSize: 30 }}
              color={isFermenting ? "green" : "red"}
              gutterBottom
            >
              {isFermenting ? "Yes" : "No"}
            </Typography>
          </Paper>
        </Box>
        <BrewGraphView></BrewGraphView>
      </div>
    );
  } else {
    return <div>No data loaded.</div>;
  }
});
