/**
 * Import all your stores
 */
import { computed, makeObservable } from "mobx";
import { BrewRepository } from "./BrewRepository";
import { SpindelRepository } from "./SpindelRepository";

/**
 * Root Store Class with
 */

export class RootRepository {
  spindelRepository: SpindelRepository;
  brewRepository: BrewRepository;

  constructor() {
    console.log("Create");
    makeObservable(this, {
      isLoading: computed,
    });
    this.spindelRepository = new SpindelRepository(this);
    this.brewRepository = new BrewRepository(this);
    //this.userStore = new UserStore(this); // Pass `this` into stores for easy communication
  }

  get isLoading(): boolean {
    const value =
      this.brewRepository.isLoading || this.spindelRepository.isLoading;
    console.log("Is loading: " + value);
    return value;
  }

  async refreshAll() {
    await this.brewRepository.loadAllBrews();
    await this.spindelRepository.loadAllUnassignedData();
  }
}
